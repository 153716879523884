import { random } from './random'

export function createRoleplayQuestion () {
  return random([
    'what was a bad memory of your family?',
    'what was a good memory of your family?',
    'what is a faction you strongly agree with?',
    'what is a faction you strongly disagree with?',
    'what is a game you like to play?',
    'what was a happy moment from your childhood?',
    "what is a monster that you don't believe is real?",
    'is there a person you are afraid of?',
    "was there ever a person you couldn't help?",
    'is there a person you hate?',
    'is there someone you love?',
    'who do you respect?',
    'is there a person you want to meet?',
    'is there a place you would love to visit?',
    'what was a sad moment from your childhood?',
    'what was a time you embarrassed yourself?',
    'what was a time you got away with something?',
    'what was a time you got a sibling into trouble?',
    'what was a time you got really drunk?',
    'what was a time you hurt someone?',
    'what was a time you made something?',
    'what was a time you were afraid?',
    'what was a time you were heroic?',
    'what was a time you were powerless?',
    'what was a time you were proud of someone?',
    'what was a time you were smarter than everyone else?',
    'are you a dog person or a cat person?',
    'are you closer to your mother or your father?',
    'is there a food that you think is disgusting?',
    'what was something that happened on your last birthday?',
    'is there something you are ashamed of?',
    'is there something you are proud of doing?',
    'what is something you would love to do?',
    "what was the best dinner you've ever had?",
    'what was the best gift you ever received?',
    "what was the funniest thing you've ever seen?",
    'what are you looking forward to?',
    'what would you do if you were king?',
    'what would you do with a million gold pieces?',
    'where are your family now?',
    'who or what would you die for?',
    'who was your first kiss?',
    'why are you with the party?',
    'why would the party fall apart without you?',
    'can you share your favourite story?',
    'can you share your favourite thing about your hometown?',
    'what is your favourite way to relax?',
    'what has been your greatest achievement?',
    'what is your greatest fear?',
    'what was your last nightmare?'
  ])
}
